import React from "react";
import { Switch, Route } from "react-router-dom";
import AuthViews from "views/auth-views";
import Loading from "components/shared-components/Loading";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { Row, Col } from "antd";

export const AuthLayout = () => {
  const { status } = useThemeSwitcher();

  if (status === "loading") {
    return <Loading cover="page" />;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "100vh",
          overflow: "hidden",
          background:
            "linear-gradient(0deg, rgba(0, 0, 0, 0.56), rgba(0, 0, 0, 0.56)),url(/img/bg.jpg)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          style={{
            maxWidth: "600px",
            borderRadius: "12px",
            overflow: "hidden",
            margin: "auto",
          }}
          className=""
        >
          <Row className="flex-column-reverse flex-md-row">
            <Col
              xs={24}
              md={20}
              style={{
                backgroundColor: "black",
                margin: "auto",
              }}
            >
              <div className="w-100 p-3">
                <Switch>
                  <Route path={""} component={AuthViews} />
                </Switch>
              </div>
            </Col>
          </Row>
        </div>
        <div
          className="d-flex pt-2"
          style={{
            width: "100%",
            maxWidth: "800px",
            justifyContent: "space-between",
          }}
        ></div>
      </div>

      <div
        style={{
          position: "absolute",
          bottom: "40px",
          right: "40px",
        }}
        className="d-none d-md-flex"
      ></div>
    </>
  );
};

export default AuthLayout;

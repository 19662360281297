import {
  AppstoreOutlined,
  BoldOutlined,
  DatabaseOutlined,
  HomeOutlined,
  ProjectOutlined,
  MessageOutlined,
  FileDoneOutlined,
  GoogleOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const extraNavTree = [];

const appsNavTree = [
  {
    key: "dashboard",
    path: `${APP_PREFIX_PATH}/dashboards`,
    breadcrumb: false,
    icon: HomeOutlined,
    submenu: [
      {
        // key: "main-home",
        key: `${APP_PREFIX_PATH}/home/edit`,
        path: `${APP_PREFIX_PATH}/home/edit`,
        title: "Home",
        icon: HomeOutlined,
        breadcrumb: true,
        submenu: [],
        permission: "brand_view",
      },
      {
        key: `${APP_PREFIX_PATH}/google-analytic/edit`,
        path: `${APP_PREFIX_PATH}/google-analytic/edit`,
        title: "Analytic",
        icon: GoogleOutlined,
        breadcrumb: true,
        submenu: [],
        permission: "analytic_view",
      },

      {
        // key: "main-blogs",
        key: `${APP_PREFIX_PATH}/services/list`,
        path: `${APP_PREFIX_PATH}/services/list`,
        title: "Services",
        icon: ProjectOutlined,
        breadcrumb: true,
        submenu: [],
        permission: "blog_view",
      },

      {
        key: "products",
        title: "Products",
        icon: AppstoreOutlined,
        breadcrumb: true,
        submenu: [
          {
            // key: "dds-engine",
            key: `${APP_PREFIX_PATH}/products/dds-engine`,
            path: `${APP_PREFIX_PATH}/products/dds-engine`,
            title: "DDSEngine",
            icon: DatabaseOutlined,
            breadcrumb: true,
            submenu: [],
            permission: "product_view",
          },
          {
            // key: "message-injector",
            key: `${APP_PREFIX_PATH}/products/message-injector`,
            path: `${APP_PREFIX_PATH}/products/message-injector`,
            title: "MessageInjector",
            icon: MessageOutlined,
            breadcrumb: true,
            submenu: [],
            permission: "product_view",
          },
          {
            // key: "doc-gen",
            key: `${APP_PREFIX_PATH}/products/doc-gen`,
            path: `${APP_PREFIX_PATH}/products/doc-gen`,
            title: "DocGen",
            icon: FileDoneOutlined,
            breadcrumb: true,
            submenu: [],
            permission: "product_view",
          },
          {
            // key: "db-connector",
            key: `${APP_PREFIX_PATH}/products/db-connector`,
            path: `${APP_PREFIX_PATH}/products/db-connector`,
            title: "DBConnector",
            icon: BoldOutlined,
            breadcrumb: true,
            submenu: [],
            permission: "product_view",
          },
        ],
        permission: "product_view",
      },
      {
        // key: "get-demo",
        key: `${APP_PREFIX_PATH}/get-demo/list`,
        path: `${APP_PREFIX_PATH}/get-demo/list`,
        title: "Get Demo",
        icon: ProjectOutlined,
        breadcrumb: true,
        submenu: [],
        permission: "get_view",
      },
    ],
  },
];

const navigationConfig = [...appsNavTree, ...extraNavTree];

export default navigationConfig;

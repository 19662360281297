import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/services`}
          component={lazy(() => import(`./services`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/home`}
          component={lazy(() => import(`./home`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/google-analytic`}
          component={lazy(() => import(`./google-analytic`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/brands`}
          component={lazy(() => import(`./brands`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/products`}
          component={lazy(() => import(`./products`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/get-demo`}
          component={lazy(() => import(`./get-demo`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/update-password`}
          component={lazy(() => import(`./update-password`))}
        />
        <Redirect from={APP_PREFIX_PATH} to={`${APP_PREFIX_PATH}/home/edit`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);

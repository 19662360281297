import { LogoutOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Menu } from "antd";
import { APP_PREFIX_PATH } from "configs/AppConfig";

import React from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { signOut } from "redux/actions/Auth";

export const NavProfile = () => {
  const history = useHistory();

  const user = useSelector((state) => state.auth.user);

  // useEffect(() => {
  //   fetchProfile();
  // }, []);

  // const fetchProfile = async () => {
  //   try {
  //     // const res = await UserService.loggedIn();
  //     // dispatch(fetchUser(res.data));
  //   } catch (error) {
  //     message.error(error.message);
  //   }
  // };
  const handleSignout = () => {
    localStorage.removeItem("auth_token");
    window.location.reload();
    history.push("/auth/login");
  };
  const handleClick = () => {
    history.push(`/app/update-password`);
  };

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar
            size={45}
            // src={`${env.BASE_IMG_URL}/user/${user?.profile_picture}`}
            src="/user.png"
          />
          <div className="pl-3">
            <h4 className="mb-0">
              {user?.first_name ?? "Admin"} {user?.last_name ?? ""}
            </h4>
            <span className="text-muted">{user?.user_type ?? ""}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          <Menu.Item key="update password" onClick={handleClick}>
            <span>Update password</span>
          </Menu.Item>
          <Menu.Item onClick={(e) => handleSignout()}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        {/* <Menu.Item key="update password" onClick={handleClick}>
          <span>Update password</span>
        </Menu.Item> */}
        <Menu.Item key="profile">
          {/* <Avatar src={`${}/user/${user?.profile_picture}`} /> */}
          <Avatar src="/user.png" />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default connect(null, { signOut })(NavProfile);
